:root {
  --color-primary: #0099CC;
  --color-danger: #dc3545;
  --color-secondary: #6c757d;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-size: calc(10px + 0.4vmin);
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0.1rem;
  margin-bottom: 0.2rem;
}

hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}

input[type=checkbox].large {
  /* Double-sized Checkboxes */
  -ms-transform: scale(2);
  /* IE */
  -moz-transform: scale(2);
  /* FF */
  -webkit-transform: scale(2);
  /* Safari and Chrome */
  -o-transform: scale(2);
  /* Opera */
  transform: scale(2);
  margin-right: 12px;
}

input[type=checkbox].red {
  accent-color: red;
}
input[type=checkbox].yellow {
  accent-color: yellow;
}
input[type=checkbox].green {
  accent-color: #00674F;
}