.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Flex-Between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Files-Header {
  background: #fff;
  padding: 0.5rem;
}

.sticky {
  top: 0;
  position: sticky;
}

.list {
  display: flex;
  flex-wrap: wrap;
}

.card {
  flex-grow: 1;
  width: 33%;
  box-sizing: boder-box;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1.2rem;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 8px -5px black;
}

@media (max-width: 600px) {
  .card {
    width: 100%;
  }
  .card-upload .btn {
    width: 100%;
  }
}

.card .item {
  width: 100%;
}

.card .item .filedetail {
  text-align: right;
  width: 100%;
}

.card .item .filedetail .last-modified {
  font-size: 75%;
}

.card-upload {
  box-sizing: boder-box;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 1.2rem;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 8px -5px black;
}

.card-upload .btn {
  width: 200px;
  display: block;
  margin-top: 0.5rem;
  color: var(--color-primary);
  background: transparent;
  border: 2px solid var(--color-primary);
  border-radius: 6px;
  box-shadow: 0 5px 9px -6px black;
  padding: 0.5rem;
  font-weight: bold;
  transition: all 0.1s ease-out;
  outline: none;
}

.card-upload .btn:active {
  transform: translateY(2px);
  box-shadow: none;
}

.badge {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
}

.badge-primary {
  color: #fff;
  background-color: var(--color-primary);
}

.badge-secondary {
  color: #fff;
  background-color: var(--color-secondary);
}

.badge-danger {
  color: #fff;
  background-color: var(--color-danger);
}

.text-muted {
  color: #6c757d !important
}

.upload-form {
  display: flex;
}


.upload-form > div {
  padding: 0 10px 0 0;
}

@media (max-width: 1080px) {
  .upload-form {
    flex-direction: column;
  }
  .upload-form > div {
    padding: unset;
    margin-top: 10px;
  }
}